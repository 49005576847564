<script>
export default {
  name: "ColumnRender",
  functional: true,
  props: {
    params: Object,
    rowData: Object,
    render: Function
  },
  render: (h, ctx) => {
    const { render, rowData, params } = ctx.props;
    return h("div", {}, [render(h, rowData, params)]);
  }
};
</script>
