<template>
  <div class="list_container">
    <slot name="header" :form="form" :listContext="listContext" class="header" />
    <van-pull-refresh v-model="status.refreshing" :disabled="offRefresh" :style="styleObj" @refresh="onRefresh">
      <van-list
        v-model="status.loading"
        :finished="status.finished"
        :finished-text="statusText.finishedText"
        :error.sync="status.error"
        :error-text="statusText.errorText"
        :immediate-check="initLoad"
        @load="onLoad"
        :class="{ isEmpty: source.length > 0 }"
      >
        <template v-for="(itemRow, index) in source">
          <!--每一列数据-->
          <van-swipe-cell :key="index">
            <slot :form="form" :row="itemRow">
              <div class="list_column shadow shadow-lg shadow-warp">
                <slot name="columnHead" :form="form" :row="itemRow"></slot>
                <van-cell-group v-if="column && column.length > 0">
                  <template v-for="(columnItem, cIndex) in column">
                    <van-cell v-if="columnItem.render" :key="cIndex" :title="columnItem.label">
                      <ColumnRender :render="columnItem.render" :params="{ listContext, ...columnItem }" :rowData="itemRow" />
                    </van-cell>
                    <van-cell v-else :key="cIndex" :title="columnItem.label" :value="itemRow[columnItem.code]" />
                  </template>
                </van-cell-group>
                <slot name="columnFoot" :form="form" :row="itemRow"></slot>
              </div>
            </slot>
            <template #right>
              <slot name="rightTool" :form="form" :row="itemRow"> </slot>
            </template>
            <template #left>
              <slot name="leftTool" :form="form" :row="itemRow"> </slot>
            </template>
          </van-swipe-cell>
        </template>
        <!--数据加载完处理-->
        <template #finished>
          <template v-if="source && source.length > 0">
            <slot name="finished">
              {{ statusText.finishedText }}
            </slot>
          </template>
          <template v-else>
            <slot name="empty">
              {{ statusText.emptyText }}
            </slot>
          </template>
        </template>
      </van-list>
    </van-pull-refresh>
    <slot name="footer" :form="form" :listContext="listContext" />
  </div>
</template>
<script>
import { request } from "@/api/service";
import ColumnRender from "./ColumnRender";
export default {
  name: "HYList",
  inheritAttrs: false,
  components: { ColumnRender },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    api: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    column: {
      type: Array,
      default: () => []
    },
    listKey: {
      type: String,
      default: "list"
    },
    initLoad: {
      type: Boolean,
      default: true
    },
    offRefresh: {
      type: Boolean,
      default: false
    },
    styleObj: {
      type: Object,
      default: () => {
        return {
          minHeight: "100vh"
        };
      }
    }
  },
  watch: {},
  data() {
    return {
      listContext: this,
      status: {
        loading: false,
        finished: false,
        error: false,
        refreshing: false
      },
      statusText: {
        finishedText: "没有更多了",
        emptyText: "暂无查询数据",
        errorText: "请求失败，点击重新加载"
      },
      source: [],
      pageForm: {
        page: 1, // 页数
        pageSize: 10 // 每页的条数
      }
    };
  },
  computed: {},
  methods: {
    onRefresh(e) {
      this.pageForm.page = 1;
      this.source = [];
      this.status.finished = false;
      this.status.refreshing = false;
      this.status.error = false;
      this.onLoadSource();
    },
    onLoad(e) {
      this.onLoadSource();
    },
    onLoadSource() {
      this.status.loading = true;
      Object.assign(this.pageForm, this.form);
      request({ url: this.api.url, method: this.api.method || "post", data: this.pageForm })
        .then(res => {
          this.pageForm.page++;
          this.source.push(...res[this.listKey]);
          this.status.loading = false;
          this.status.refreshing = false;
          if (this.source.length >= res.total) {
            this.status.finished = true;
          }
        })
        .catch(error => {
          this.status.loading = false;
          this.status.refreshing = false;
          this.status.error = true;
          this.statusText.errorText = (error.message || "请求失败") + ",点击重新加载";
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.list_column {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 8px 5px;
}
.header {
  background: $white;
}
.isEmpty ::v-deep {
  background: rgb(239, 239, 249);
  .van-list__finished-text {
    background: #fff;
  }
}
</style>
